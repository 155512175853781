@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(90deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(90deg);
    }

    100% {
        transform: rotate(0deg);
    }
}