:root {
  --blue: #1263AF;
  --white: #fff;
  --dark-gray: #666666;
  --outline-gray: #ECECEC;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.2;
}

body > iframe {
  display: none !important;
}

.dark-gray {
  color: var(--dark-gray);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding: 12px;
}

ol, ul, menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

button,
a,
trix-editor {
  outline: none !important;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1,
.h1 {
  font-size: 3rem;
}
h2,
.h2 {
  font-size: 2.5rem;
}
h3,
.h3 {
  font-size: 2.25rem;
}
h4,
.h4 {
  font-size: 2rem;
}
h5,
.h5 {
  font-size: 1.5rem;
}
h6,
.h6 {
  font-size: 1.25rem;
}
.h7 {
  font-size: 1rem;
}
.h8 {
  font-size: 0.875rem;
}
.h9 {
  font-size: 0.75rem;
}

select {
  padding: 0px 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

trix-editor {
  transition: .2s ease;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -ms-transition: .2s ease;
  -o-transition: .2s ease;
}
trix-editor:is(:hover, :focus) {
  border-color: #2196F3 !important;
}
.trix-button--icon-youtube::before {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256.4 74.2c22.7 0 45.3-.4 68 .1 28.8.6 57.6 1.7 86.3 3 11.9.6 24 .9 35.8 3.2 28.1 5.4 44.8 22.7 52.5 50 4.1 14.5 5.6 29.4 6.9 44.3 3.5 40.1 2.8 80.2 1.8 120.3-.6 23.7-1.6 47.6-7.6 70.8-2.5 9.5-5.4 19-10.9 27.3-10 15.1-24.4 23.7-42 26.9-15.1 2.8-30.4 3.1-45.7 3.8-28.3 1.3-56.6 1.9-84.9 2.6-33.5.9-67 .7-100.4.4-27.1-.3-54.3-.9-81.4-2-22.9-.9-45.9-1.3-68.5-5.7-12.5-2.4-23.6-7.6-33.2-16.2-9-8.2-13.6-18.8-17-30-7.3-24-7.8-48.8-9.3-73.6-1.7-29-1.2-58-.5-86.9.6-24.4 1.7-48.9 6.8-72.9 3-14.2 7.1-28.3 17.4-39.2C43.2 87 59.4 80.6 77.7 79.3c21.9-1.5 43.9-2.5 65.8-3.4 37.6-1.6 75.3-2.1 112.9-1.7zm83.1 171.2c-.4-1.2-1.5-1.4-2.3-1.8-23.5-12.4-47.1-24.8-70.7-37.2-18.7-9.8-37.4-19.7-56.1-29.6-5.2-2.7-5.3-2.6-5.3 3.4v131.9c-.1 2.6.9 3.4 3.3 2.1 2.9-1.6 5.8-3.2 8.8-4.8 39.4-20.4 78.9-40.9 118.3-61.3 1.4-.8 3.2-1.1 4-2.7z' /%3E%3C/svg%3E") !important;
  top: 8% !important;
  bottom: 4% !important;
}
.trix-button--icon-html::before {
  content: 'HTML' !important;
  top: 8% !important;
  bottom: 4% !important;
  text-indent: 0px !important;
}

trix-toolbar .trix-button[data-trix-action="attachFiles"],
trix-toolbar .trix-button[data-trix-attribute="heading1"] {
  display: none !important;
}
trix-toolbar .trix-button[data-trix-attribute="youtubeVideo"],
trix-toolbar .trix-button[data-trix-attribute="heading2"] {
  border-left: none !important;
}
trix-toolbar .trix-button[data-trix-attribute="customHTML"] {
  border: 1px solid #ccc;
  height: calc(100% - 10px);
  margin-left: 16px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  width: 60px !important;
}

.trix-button {
  transition: .2s ease;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -ms-transition: .2s ease;
  -o-transition: .2s ease;
}
.trix-button:is(:hover, :active) {
  background-color: #cbeefa;
}

.multi-select {
  position: relative;
  z-index: 2;
}

.multi-select-placeholder-container {
  position: relative;
  display: flex;
  z-index: 1;
  height: 0;
  transition: .2s ease;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -ms-transition: .2s ease;
  -o-transition: .2s ease;
  transform: translate(12px, 12px) scale(1);
  -webkit-transform: translate(12px, 12px) scale(1);
  -moz-transform: translate(12px, 12px) scale(1);
  -ms-transform: translate(12px, 12px) scale(1);
  -o-transform: translate(12px, 12px) scale(1);
}
.multi-select-placeholder-container_open {
  transform: translate(-115px, -6px) scale(0.75);
  -moz-transform: translate(-115px, -6px) scale(0.75);
  -ms-transform: translate(-115px, -6px) scale(0.75);
  -o-transform: translate(-115px, -6px) scale(0.75);
  -webkit-transform: translate(-115px, -6px) scale(0.75);
}

.multi-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1.15;
  pointer-events: none;
  background-color: #fff;
  margin: 0;
  padding: 0 3px;
  height: 14px;
}

.select__control,
textarea,
input {
  transition: .2s ease;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -ms-transition: .2s ease;
  -o-transition: .2s ease;
}

.select__control {
  height: 40px;
  box-shadow: none !important;
}
.select__control:is(:active, :hover),
textarea:is(:active, :hover, :focus),
input:is(:active, :hover, :focus) {
  border-color: #2196F3 !important;
}

.multi-select-container .select__multi-value {
  background-color: #1263AF;
  color: #fff;
  margin-top: 6px;
  transition: .2s ease;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -ms-transition: .2s ease;
  -o-transition: .2s ease;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.multi-select-container .select__multi-value__label {
  color: inherit;
  padding: 2px 0 4px 9px;
}

.multi-select-container .select__multi-value:is(:active, :hover) {
  background-color: #0070DA;
}
/* .multi-select-container .select__multi-value:is(:active, :hover) .select__multi-value__label {
  text-decoration: line-through;
} */

.multi-select-container .select__multi-value__remove {
  padding: 0 0 0 3px;
  height: 20px;
  width: 20px;
  margin: auto 3px;
}
.multi-select-container .select__multi-value__remove:is(:hover, :active) {
  background-color: #fff;
  color: #0070DA; 
  cursor: pointer;
}

.btn {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.15;
  color: var(--white);
  background-color: var(--blue);
  border: 1px solid var(--blue);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  transition: .2s ease;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -ms-transition: .2s ease;
  -o-transition: .2s ease;
}
.btn:is(:hover, :active) {
  cursor: pointer;
  opacity: .85;
}

.btn-outline {
  color: rgba(0, 0, 0, 0.5);
  background-color: var(--white);
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-outline:is(:hover, :active) {
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-add {
  background-color: #44CB3A;
  border-color: #44CB3A;
}
.btn-delete {
  background-color: #EC3D47;
  border-color: #EC3D47;
}
/* .btn-add:is(:hover, :active) {
  opacity: 0.8;
} */

.custom-HTML-show-container {
  font-size: 16px;
  line-height: 1.4;
  color: var(--black);
  display: flex;
  flex-direction: column;
}

.custom-HTML-show-container div {
  display: inline-block;
}

.custom-HTML-show-container br {
  /* content: ""; */
  line-height: 16px;
}

:is(.custom-HTML-show-container, trix-editor) h6 {
  font-size: 20px;
}
:is(.custom-HTML-show-container, trix-editor) :is(p, ul, ol) {
  font-size: 16px;
}

:is(.custom-HTML-show-container, trix-editor) ul li::before {
  content: '—';
  color: #EC3D47;
  margin-right: 4px;
}

:is(.custom-HTML-show-container, trix-editor) ol {
  list-style: decimal;
  margin-left: 16px;
}

:is(.custom-HTML-show-container, trix-editor) :is(p, ul, ol):nth-child(n+2) {
  margin-top: 4px;
}

:is(.custom-HTML-show-container, trix-editor) :is(ol, ul) li:nth-child(n+2) {
  margin-top: 2px;
}

:is(.custom-HTML-show-container, trix-editor) blockquote {
  padding: 8px 16px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  background-color: #ccc;
}

@keyframes ico_twisting {
  0% {
      transform: rotate(0deg);
  }

  50% {
      transform: rotate(90deg);
  }

  100% {
      transform: rotate(0deg);
  }
}